import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useEffect, useState, useCallback } from 'react';

import SearchbarStyles from '../../assets/jss/components/SearchbarStyles';

// ui reflection of home view
export function SearchBar(props) {
  const {
    classes,
    searchValue,
    handleTextChange,
    handleClear,
    disableAutoFocus,
    placeholder,
    disabled,
    width,
    variant,
    searchAdornmentPos,
    isClearSearch,
  } = props;

  const [currentValue, setCurrentValue] = useState(searchValue || '');
  const [currentPage, setCurrentPage] = useState(window.location.pathname);

  useEffect(() => {
    if (currentPage !== window.location.pathname) {
      setCurrentPage(window.location.pathname);
      setCurrentValue('');
    }
  }, [currentPage]);

  useEffect(
    () => () => {
      setCurrentValue('');
    },
    []
  );

  // SHP-3552 clear the value with trigger
  useEffect(() => {
    if (isClearSearch) {
      setCurrentValue('');
    }
  }, [isClearSearch]);

  const handleChange = useCallback(
    (e) => {
      setCurrentValue(e.target.value);
      handleTextChange(e);
    },
    [handleTextChange]
  );

  const handleClearSearch = useCallback(() => {
    setCurrentValue('');
    handleClear();
  }, [handleClear]);

  const inputProps = {
    endAdornment: (
      <InputAdornment position="end" style={{ opacity: 0.7 }}>
        {(Array.isArray(currentValue) && currentValue.length) || (!Array.isArray(currentValue) && currentValue) ? (
          <IconButton className={classes.clearIcon} onClick={handleClearSearch} disabled={!currentValue}>
            <ClearIcon />
          </IconButton>
        ) : (
          searchAdornmentPos === 'end' && <SearchIcon />
        )}
      </InputAdornment>
    ),
  };

  if (searchAdornmentPos === 'start') {
    inputProps.startAdornment = (
      <InputAdornment position="start" style={{ opacity: 0.7 }}>
        <SearchIcon />
      </InputAdornment>
    );
  }

  return (
    <Grid container>
      <Grid item xs={width || 11}>
        <TextField
          variant={variant}
          className={`${classes.searchText} ${variant && variant === 'outlined' ? classes.searchTextOutlined : ''}`}
          autoFocus={disableAutoFocus !== true}
          value={currentValue}
          onChange={handleChange}
          fullWidth
          placeholder={placeholder || 'Search'}
          disabled={disabled}
          InputProps={inputProps}
        />
      </Grid>
    </Grid>
  );
}

export default withStyles(SearchbarStyles)(SearchBar);
