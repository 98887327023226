import { Box, Button, Step, StepLabel, Stepper, Typography, makeStyles } from '@material-ui/core';
import { Error } from '@progress/kendo-react-labels';
import React, { useState, useReducer, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { showNotification } from '../../Notifications/notificationSlice';
import DropdownField from '../../Shared/Forms/KendoFields/DropdownField';
import TextFieldInput from '../../Shared/Forms/KendoFields/TextFieldInput';
import utils from '../../Shared/Utils/utils';
import { selectUserDetails } from '../../Users/selectors';
import { getUser } from '../../Users/usersSlice';
import { OverlayLoading } from '../helper/SimulatorLoading';
import { CONSULT_STATUS } from '../helper/steadyMDConstants';
import { selectConsultDetails, selectLatestConsult, selectNotificationLoading } from '../slice/selector';
import {
  consultStatusNotificaiton,
  fetchLatestConsult,
  resetNetsuiteLoading,
  getConsultDetails,
} from '../slice/steadyMDSimulatorSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}));

const consultStatusOptions = CONSULT_STATUS.map((i) => ({ value: i, display: utils.removeUnderscoresTitleCase(i) }));

const consultStatusDropdownField = {
  type: 'dropdown',
  name: 'consultStatus',
  label: 'Status',
  labelField: 'display',
  itemKey: 'value',
  options: consultStatusOptions,
};

const consultIdField = {
  type: 'text',
  name: 'consultId',
  label: 'Consult ID',
  readOnly: false,
  required: true,
};

const consultStatusReducer = (state, action) => ({
  ...state,
  // if it is a function
  ...(typeof action === 'function' ? action(state) : action),
});

const defaultState = {
  consultStatus: null,
  consultId: '',
  consultStatuses: consultStatusOptions,
};

function ConsultNotificationSimulator() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();

  const notificationLoading = useSelector(selectNotificationLoading);
  const [consultStatusState, setConsultStatusState] = useReducer(consultStatusReducer, defaultState, (init) => {
    return init;
  });

  /* need to get user's "_id" not just email/userId */
  const { userId } = params;
  const userDetails = useSelector(selectUserDetails);
  const latestConsult = useSelector(selectLatestConsult);
  const isMounted = useRef(false);
  const consultDetails = useSelector(selectConsultDetails);

  useEffect(() => {
    const getUserData = async () => {
      dispatch(getUser(userId, true));
    };

    const getLatestConsult = async () => {
      dispatch(fetchLatestConsult({ tenantId, userId }));
    };

    getUserData();
    getLatestConsult();

    isMounted.current = true;
  }, [userId]);
  /* END - getting userDetails for _id */

  useEffect(() => {
    if (consultDetails) {
      const statusVal = consultStatusOptions.find((i) => i.value === consultDetails?.status);
      if (statusVal) {
        setConsultStatusState({ consultStatus: statusVal });
      } else {
        setConsultStatusState({ consultStatus: null });
      }
    }
  }, [consultDetails]);

  const { consultStatus, consultId, consultStatuses } = consultStatusState;
  const [error, setError] = useState({});

  const accountId = localStorage.getItem('accountId');
  const tenantId = localStorage.getItem('tenantId');

  const handleStatusChange = (fieldName, fieldVal) => {
    /* Clear Error */
    const clearedErr = { ...error };
    delete clearedErr['consultStatus'];
    setError({ ...clearedErr });

    setConsultStatusState({ consultStatus: fieldVal });
  };

  const handleIdChange = (e) => {
    /* Clear Error */
    const clearedErr = { ...error };
    delete clearedErr['consultId'];
    setError({ ...clearedErr });

    setConsultStatusState({ consultId: e.target.value });
  };

  useEffect(() => {
    if (notificationLoading === 'fulfilled' || notificationLoading === 'rejected') {
      dispatch(resetNetsuiteLoading());
    }
  }, [notificationLoading]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const hasError = validateInputs();

    if (hasError) return null;

    const userIdPayload = userDetails?._id ?? null;
    if (!userIdPayload) return;

    const payload = {
      event: consultStatus?.value,
      formData: {
        id: consultId,
        accountID: accountId,
        tenantID: tenantId,
        user: userId,
      },
    };

    try {
      const result = await dispatch(consultStatusNotificaiton(payload));

      if (result.error) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result.error.message}`, 'error'));
        dispatch(resetNetsuiteLoading());
        return;
      }

      if (result.payload[1]?.status >= 400) {
        dispatch(showNotification(`Failed to execute simulator <br />Error: ${result?.payload[1]?.message}`, 'error'));
        dispatch(resetNetsuiteLoading());
        return;
      }

      if (result.payload[0]?.success) {
        dispatch(showNotification(`${result?.payload[0]?.message} <br />Updated successfully`, 'success'));
        dispatch(getConsultDetails(consultId));
        return;
      }
    } catch (error) {
      dispatch(showNotification(`Failed to execute simulator <br />Error: ${error.message}`, 'error'));
      dispatch(resetNetsuiteLoading());
    }
  };

  const validateInputs = () => {
    let hasError = false;
    if (!consultStatus) {
      setError((err) => ({
        ...err,
        consultStatus: {
          message: 'Status is required',
        },
      }));
      hasError = true;
    }

    if (!consultId) {
      setError((err) => ({
        ...err,
        consultId: {
          message: 'Consult ID is required.',
        },
      }));
      hasError = true;
    }

    return hasError;
  };

  const onFetchDetails = () => {
    dispatch(getConsultDetails({ userId, tenantId, consultId }));
  };

  return (
    <Box width={'100%'} height={'100%'}>
      <Box style={{ border: '1px solid gray', marginTop: 10, marginBottom: 10,padding: 10 }}>
        {(notificationLoading === 'pending' || !isMounted.current) && <OverlayLoading />}
        {latestConsult && (
          <Box sx={{ display: 'flex' }}>
            <Typography style={{ marginRight: 20 }}> Latest Consult ID: {latestConsult?.consultId} </Typography>
            <Typography> Status : {latestConsult?.status}</Typography>
          </Box>
        )}
      </Box>
      <Box
        display={'grid'}
        gridTemplateColumns={'repeat(2, minmax(0, 1fr))'}
        gridRowGap={'50px'}
        gridColumnGap={'100px'}
        marginTop={'25px'}
        marginBottom={'25px'}
      >
        <Box style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ flex: 1, display: 'flex', marginBottom: 10 }}>
            <span style={{ flex: 1 }}>
              <TextFieldInput field={consultIdField} value={consultId} handleChange={handleIdChange} />
            </span>
            {error?.consultId && <Error> {error?.consultId?.message} </Error>}
            <div style={{ marginLeft: 10 }}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={onFetchDetails}
                style={{ marginLeft: 10 }}
              >
                Fetch Details
              </Button>
            </div>
          </div>
          <div className={classes.root}>
            {consultDetails && (
              <Stepper activeStep={CONSULT_STATUS.indexOf(consultDetails?.status)} style={{ paddingLeft: 0 }}>
                {consultStatusOptions.map((option, index) => {
                  const stepProps = {};
                  return (
                    <Step key={option.value} {...stepProps}>
                      <StepLabel>{option.display}</StepLabel>
                    </Step>
                  );
                })}
              </Stepper>
            )}
          </div>
        </Box>
        <Box textAlign={'center'}>
          <DropdownField
            field={{ ...consultStatusDropdownField, options: consultStatuses }}
            value={consultStatus}
            handleChangeWithParams={handleStatusChange}
          />
          {error?.consultStatus && <Error> {error?.consultStatus?.message} </Error>}
        </Box>
        <Box gridColumn={'2 / 3 '} display="flex" justifyContent={'center'}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Simulate
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ConsultNotificationSimulator;
