import * as htmlUtils from '../../Shared/Grid/helpers';
import utils from '../../Shared/Utils/utils';
import ReportsCustomCell from '../ReportsCustomCell';

import { reportTypes, statusMapping } from './mappings';

export const initialSorting = {
  field: 'createdOn',
  dir: 'desc',
};

const formatReportType = (reportType) => {
  if (reportType.includes('CRO')) {
    return `${reportType.split('_')[0]} ${utils.removeUnderscoresTitleCase(reportType).split(' ')[1]}`;
  }
  if (reportType.includes('LOGIN')) {
    const [, report] = reportType.split('_');
    return `Log-in ${utils.removeUnderscoresTitleCase(report)}`;
  }
  return utils.removeUnderscoresTitleCase(reportType);
};

export const transform = (list) => ({
  ...list,
  items:
    list &&
    list.items &&
    list.items.map((report) => ({
      ...report,
      createdOn: utils.formatDateTime(report.createdOn),
      type: formatReportType(report.type),
      status: utils.removeUnderscoresTitleCase(report.status),
    })),
});

export const columns = [
  {
    id: 'id',
    label: 'ID',
    show: false,
    sortable: false,
    minWidth: 170,
  },
  {
    id: 'type',
    label: 'Type',
    show: true,
    locked: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'createdOn',
    label: 'Created',
    show: true,
    minWidth: 170,
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    show: true,
    minWidth: 170,
    sortable: true,
    cell: htmlUtils.withMapping(htmlUtils.tag, statusMapping),
  },
  {
    id: 'action',
    label: 'Action',
    show: true,
    minWidth: 100,
    cell: ReportsCustomCell,
    sortable: false,
  },
];

export const tableButtons = (
  handleRefresh,
  handleAssessmentReportType,
  handleScanReportType,
  handleReportReportType,
  handleCROReportType,
  handleLabTestReportType,
  handleActiveUserReportType,
  handleLoginReportType,
  handleDemographicsReportType,
  handleActivityReportType,
) => [
  {
    title: 'Refresh Table',
    buttonType: 'icon',
    variant: 'outlined',
    action: handleRefresh,
    icon: 'Sync',
  },
  {
    title: 'Generate New Report',
    buttonType: 'menu',
    variant: 'contained',
    menuItems: [
      {
        title: reportTypes.ASSESSMENT_REPORT.title,
        action: handleAssessmentReportType,
      },
      {
        title: reportTypes.SCAN_REPORT.title,
        action: handleScanReportType,
      },
      {
        title: reportTypes.USER_REPORT.title,
        action: handleReportReportType,
      },
      {
        title: reportTypes.CRO_REPORT.title,
        action: handleCROReportType,
      },
      {
        title: reportTypes.LABTEST_REPORT.title,
        action: handleLabTestReportType,
      },
      {
        title: reportTypes.ACTIVE_USER_REPORT.title,
        action: handleActiveUserReportType,
      },
      {
        title: reportTypes.LOGIN_REPORT.title,
        action: handleLoginReportType,
      },
      {
        title: reportTypes.DEMOGRAPHICS_REPORT.title,
        action: handleDemographicsReportType,
      },
      {
        title: reportTypes.ACTIVITY_REPORT.title,
        action: handleActivityReportType,
      },
    ],
  },
  // {
  //   title: "Request User Report",
  //   buttonType: "action",
  //   variant: "contained",
  //   action: handleRequestUserReport,
  // },
  // {
  //   title: "Request Scan Report",
  //   buttonType: "action",
  //   variant: "contained",
  //   action: handleRequestScanReport,
  // }
];
