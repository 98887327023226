/* eslint-disable no-new-func */
/* eslint-disable no-bitwise */
import createDOMPurify from 'dompurify';
import { encode as encodeTags, decode as decodeTags } from 'html-entities';
import moment from 'moment/moment';
import React from 'react';

const DOMPurify = createDOMPurify(window);

const utils = {
  isAfter: (date1, date2) => moment(date1).isAfter(date2),
  formatDate: (date) => moment.utc(date).format('L'),
  formatDisplayDate: (date) => moment(date).format('MM/DD/YYYY'),
  formatDateNoLocale: (date) => moment(date).format('YYYY-MM-DD'),
  formatTimeLocale: (date) => moment(date).local().format('HH:mm'),
  formatDateTime: (date) => moment(date).format('L HH:mm'),
  formatDateTimeLocale: (date) => moment(date).local().format('MM/DD/YYYY HH:mm'),
  formatDateTimeNoLocale: (date) => moment(date).utc().format('MM/DD/YYYY HH:mm'),
  formatDateTimeLong: (date) => moment(date).local().format('MM/DD/YYYY h:mm:ss A'),
  formatToUnixTime: (date) =>
    // const timeZone = moment.tz.guess();
    // const locationDate = momentTz
    //   .utc(date)
    //   .add(momentTz(date).tz(timeZone).utcOffset(), "minutes")
    //   .toISOString();
    //
    // return moment(locationDate).format("X");

    // format to unix time with keepLocalTime
    moment(date).utc(true).format('X'),
  formatDateTimeCustom: (date, timeZone) =>
    // timeZone value can be found in state.network.tenantNetwork.reportingTimezone, eg "UTC-04:00"
    moment(date).utcOffset(timeZone).format('L HH:mm'),
  formatTestImageDate(imageTime) {
    const date = moment(imageTime).local().format('MM/DD/YYYY');
    const time = moment(imageTime).local().format('h:mm a');

    return `${date} at ${time}`;
  },
  formatDateTimeLocaleJSON: (date) => moment(date).local().format(),
  formatDateReport: (date) => moment(date).format('MMDDYYYY'),
  formatDateToDateName: (date) => moment(date.trim()).format('MMM D'),
  // duration - seconds, minutes, hours, days, weeks, months, years
  diffByDates: (startDate, endDate, duration = 'date') => {
    return moment(startDate).diff(moment(endDate), duration) + 1;
  },
  capitalizeString: (string) => {
    string = string?.toLowerCase().split(' ');
    for (let i = 0; i < string.length; i++) {
      string[i] = string[i].charAt(0).toUpperCase() + string[i].slice(1);
    }
    return string.join(' ');
  },
  removeUnderscores: (string) => string?.split('_').join(' '),
  removeSpacesAfterLastWord: (value) => value.trim(),
  removeUnderscoresTitleCase: (string) => {
    const s = string.split('_').join(' ').split('-').join(' ').toLowerCase().split(' ').filter(Boolean);
    for (let i = 0; i < s.length; i++) {
      s[i] = s[i][0].toUpperCase() + s[i].slice(1);
    }
    return s.join(' ');
  },
  splitCamelcaseString: (string) => string.replace(/([a-z0-9])([A-Z])/g, '$1 $2'),
  camelToSnake: (str) =>
    str?.replace(
      /([a-z][A-Z])/g,
      (group) => `${group.toLowerCase().substring(0, 1)}-${group.toLowerCase().substring(1)}`
    ),
  snakeToCamel: (str, divider = '') =>
    str?.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', divider).replace('_', divider)),
  rgbToHex: (a) => {
    a = a.replace(/[^\d,]/g, '').split(',');
    return `#${((1 << 24) + (+a[0] << 16) + (+a[1] << 8) + +a[2]).toString(16).slice(1)}`;
  },
  toUserFriendlySize: (bytes) => {
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    return `${(bytes / 1024 ** i).toFixed(2) * 1} ${sizes[i]}`;
  },
  getPropertyByName: (path, obj, separator = '.') => {
    const properties = Array.isArray(path) ? path : path.split(separator);
    return properties.reduce((prev, curr) => prev && prev[curr], obj);
  },
  getTranslation: (i18n, props, valueKey, labelKey, translationFor) => {
    const localeKey = props.find((item) => item[valueKey] === translationFor)?.[labelKey];
    return i18n[localeKey] || translationFor;
  },
  flattenKeys: (prefix, obj) =>
    obj &&
    Object.keys(obj).reduce(
      (accum, key) => ({
        ...accum,
        [`${prefix}__${key}`]: obj[key],
      }),
      {}
    ),
  unFlattenKeys: (prefix, obj) =>
    Object.keys(obj)
      .filter((key) => key.startsWith(`${prefix}__`))
      .reduce(
        (accum, key) => ({
          ...accum,
          [key.replace(`${prefix}__`, '')]: obj[key],
        }),
        {}
      ),
  extractField: (list, field) => list.map((i) => i[field]),
  distinctValues: (list) =>
    list.reduce((accum, value) => {
      if (!accum.includes(value)) accum.push(value);
      return accum;
    }, []),
  getId: () => Math.random().toString(36).substr(2, 9),
  merge: (a, b, p) => a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b),
  getStartDate: (noOfDays) => {
    const d = new Date();
    const date = d.setDate(d.getDate() - noOfDays);
    // Get the time value in milliseconds and convert to seconds
    return (date / 1000) | 0;
  },
  // regex for look behind to support safari browsers
  numberWithCommas(num) {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
  createIFrameContentData: (html) => `data:text/html,<meta charset="utf-8"/>${html}`,
  renderHTMLTags: (data) => (
    <span
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(data),
      }}
    />
  ),
  renderHTMLAsText: (data) => {
    if (!data) return '';
    const container = document.createElement('div');
    container.innerHTML = data;
    return container.textContent || container.innerText || '';
  },
  findScript: (text) => {
    if (text) {
      // eslint-disable-next-line
      text = text.replace(new RegExp('\r?\n', 'g'), '');
      const decodedValue = utils.decodeTags(text);
      const foundScript = utils.parseJsScript(decodedValue);
      if (foundScript && foundScript[1]) {
        const clearedScript = foundScript[1].replace(/<[^>]*>?/gm, '');
        // execute script only in questionnaire not in outcome builder
        if (clearedScript) {
          return utils.executeScript(clearedScript);
        }
      }
    }
  },
  parseJsScript: (value) => {
    // eslint-disable-next-line
    let foundScript = value.match(/\<script\>(.*)\<\/script\>/);
    if (foundScript && foundScript[1]) {
      const clearedScript = foundScript[1].replace(/<[^>]*>?/gm, '');
      foundScript[1] = clearedScript;
    }
    return foundScript;
  },
  encodeTags,
  decodeTags,
  executeScript: (code = '') => {
    if (code) {
      try {
        // eslint-disable-next-line
        const innerScript = code.replace(/\<[\/]*script\>/, '');
        const testScript = new Function(innerScript);
        testScript();
        const script = document.createElement('script');
        script.innerHTML = code;
        script.async = true;
        document.body.appendChild(script);
        return true;
      } catch (e) {
        alert(`Script error: \n${e.message}`);
        return false;
      }
    }
  },
  convertBase64ToBlobUrl: (b64Data, mime = 'image/png') => {
    const sliceSize = 512;
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: mime });
    return URL.createObjectURL(blob);
  },
  appVersion: () => {
    const APP_VERSION = process.env.REACT_APP_VERSION;
    let version = APP_VERSION ? APP_VERSION.replace('-migration', '') : '2.2.0';
    if (!version.startsWith('v')) {
      version = `v${version}`;
    }
    return version;
  },
  createImg: (data) => {
    const url = URL.createObjectURL(data);
    const img = new Image();
    img.src = url;
    img.alt = 'user profile avatar';

    return img;
  },
  validateEmail: (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  },
  sortBy: (fieldName) => (a, b) => {
    if (a[fieldName]) {
      let valueA = a[fieldName]["translations"]
        ? a[fieldName]["translations"]["eng"].toUpperCase()
        : a[fieldName].toUpperCase(); // ignore upper and lowercase
      let valueB = b[fieldName]["translations"]
        ? b[fieldName]["translations"]["eng"].toUpperCase()
        : b[fieldName].toUpperCase(); // ignore upper and lowercase
      if (valueA < valueB) {
        return -1;
      }
      if (valueA > valueB) {
        return 1;
      }
    }

    // names must be equal
    return 0;
  },
  stripeString: (string) => {
    return string.replace(/(<([^>]+)>)/gi, "");
  },
};

export default utils;
