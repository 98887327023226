export const vaccinationRecordMappings = {
  AVAILABLE: {
    label: 'Available',
    color: 'green',
  },
  PENDING_APPROVAL: {
    label: 'Pending Approval',
    color: 'orange',
  },
  NOT_AVAILABLE: {
    label: 'Not Available',
    color: 'grey',
  },
};

export const countryIncludes = [
  null,
  'USA',
  'United States (USA)',
  'American Samoa',
  'Micronesia (Federated States of)',
  'Marshall Islands (the)',
  'Guam',
  'Puerto Rico',
  'Palau',
];

export const formSelects = [
  'Sex',
  'State',
  'Relationship',
  'Country',
  'Birth State',
  'Race',
  'Ethnicity',
  'Guardian Relation',
];

// Vaccination Record Type Constants
export const VAX_TYPES = Object.freeze({
  ATTESTATION: 'ATTESTATION',
  EXEMPTION: 'EXEMPTION',
  SAFEPASS: 'SAFEPASS',
  ONEUPHEALTH: 'ONEUPHEALTH',
});
