import { makeStyles } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import AssignmentRoundedIcon from '@material-ui/icons/AssignmentRounded';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FolderRoundedIcon from '@material-ui/icons/FolderRounded';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import LayersIcon from '@material-ui/icons/Layers';
import LocalHospitalRoundedIcon from '@material-ui/icons/LocalHospitalRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import PeopleAltRoundedIcon from '@material-ui/icons/PeopleAltRounded';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import WidgetsRoundedIcon from '@material-ui/icons/WidgetsRounded';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import SidebarStyles from '../../assets/jss/components/SidebarStyles';
import { getUserFromToken } from '../Auth/helpers';
import { selectTenantSettings } from '../Settings/selectors';
import AccessControl from '../Shared/AccessControl';
import { selectCurrentTenant } from '../Shared/selectors';
import utils from '../Shared/Utils/utils';
import { selectUserAccountPermission } from '../Users/selectors';

import { pages, pages2, settingsPages, virtualConsultPages } from './SidebarPages';

const icons = [
  <HomeRoundedIcon />,
  <PersonRoundedIcon />,
  <PeopleAltRoundedIcon />,
  <AssignmentRoundedIcon />,
  <LayersIcon />,
  <LocalHospitalRoundedIcon />,
  <LayersIcon />,
  <FolderRoundedIcon />,
  <EqualizerIcon />,
  <WidgetsRoundedIcon />,
];

export function Sidebar(props) {
  const { onMouseLeave, open, type, handleChangeTenant, allTenants, allTenantsLoading, logout } = props;

  const classes = useStyles();
  const currentTenant = useSelector(selectCurrentTenant);
  const userAccountPermission = useSelector(selectUserAccountPermission);
  const tenantSettings = useSelector(selectTenantSettings);

  const { pathname } = useLocation();

  const [openSettings, setOpenSettings] = useState(false);
  const [openContent, setOpenContent] = useState(false);
  const [openAdmin, setOpenAdmin] = useState(false);
  const [openVisualConsult, setOpenVisualConsult] = useState(false);
  const user = getUserFromToken();

  const accountAdminAccess = userAccountPermission && userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR';

  useEffect(() => {
    if (type !== 'mobile' && !open) {
      setOpenSettings(false);
      setOpenContent(false);
      setOpenAdmin(false);
      setOpenVisualConsult(false);
    }
  }, [type, open]);

  const handleOpen = (dropdown) => {
    setOpenSettings(dropdown === 'settings' ? !openSettings : false);
    setOpenContent(dropdown === 'content' ? !openContent : false);
    setOpenAdmin(dropdown === 'admin' ? !openAdmin : false);
    setOpenVisualConsult(dropdown === 'virtual-consult' ? !openVisualConsult : false);
  };

  const handleMouseLeave = () => {
    setOpenSettings(false);
    setOpenContent(false);
    setOpenAdmin(false);
    setOpenVisualConsult(false);
    onMouseLeave();
  };

  const listItemLink = (listItem) => {
    const { link, key, className, className2, icon, text, allowedFor, root } = listItem;

    // if route in menuItems set to false, do not render
    const route = link.split('/').slice(2).join('/');
    // check if user has admin permissions to render account settings
    const accountAdminAccess = route.includes('account');
    const accountAdmin =
      accountAdminAccess && userAccountPermission && userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR';
    // check if item is not in menuItems list (can always view), if item is in menuItems and true, or if admin route is permitted
    const menuItemEnabled =
      tenantSettings && key && (!(key in tenantSettings.menuItems) || tenantSettings.menuItems[key]);
    const conditionalRender = (!accountAdminAccess && menuItemEnabled) || accountAdmin;
    const selected = pathname === link || (root && pathname.includes(root));

    // render only true conditional render items
    if (conditionalRender) {
      return allowedFor ? (
        <AccessControl allowedPermissions={allowedFor} key={key}>
          <Link to={link} key={key} className={className} onClick={handleMouseLeave}>
            <ListItem button className={className2} selected={selected}>
              {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
              <ListItemText primary={text} />
            </ListItem>
          </Link>
        </AccessControl>
      ) : (
        <Link to={link} key={key} className={className} onClick={handleMouseLeave}>
          <ListItem button className={className2} selected={selected}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={text} />
          </ListItem>
        </Link>
      );
    }
  };

  const listItemButton = (key, icon, text, openList, root) => {
    const accountAdminAccess = userAccountPermission && userAccountPermission.permission === 'ACCOUNT_ADMINISTRATOR';
    const menuItemEnabled =
      tenantSettings && key && (!(key in tenantSettings.menuItems) || tenantSettings.menuItems[key]);
    const conditionalRender = (key !== 'admin' && menuItemEnabled) || (key === 'admin' && accountAdminAccess);

    const selected = root && pathname.includes(root);

    if (conditionalRender) {
      return (
        <ListItem button onClick={() => handleOpen(key)} selected={selected}>
          <ListItemIcon>{icon}</ListItemIcon>
          <ListItemText primary={text} />
          {openList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
      );
    }
  };
  const id_token = localStorage.getItem('id_token');
  if ((!id_token || !currentTenant || !tenantSettings || !tenantSettings.menuItems) && currentTenant !== 'None') {
    return null;
  }

  return (
    <List
      className={type === 'mobile' ? classes.mobileSidebar : null}
      style={{
        backgroundColor: '#fff',
        zIndex: '999',
        overflowX: 'hidden',
      }}
    >
      {type === 'mobile' && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentTenant.id || ''}
          onChange={(e) => handleChangeTenant(e.target.value)}
          variant="outlined"
          className={classes.mobileTenant}
          displayEmpty
        >
          <MenuItem dense value="" disabled>
            Select Tenant
          </MenuItem>
          {!allTenantsLoading &&
            allTenants &&
            allTenants.items &&
            allTenants.items.map((tenant) => (
              <MenuItem dense key={tenant.id} value={tenant.id}>
                {tenant.title}
              </MenuItem>
            ))}
        </Select>
      )}
      {currentTenant !== 'None' && (
        <AccessControl
          allowedPermissions={['ADMINISTRATOR', 'ADMINISTRATOR_RESTRICTED']}
          renderNoAccess={() => <div />}
        >
          {pages(currentTenant, user).map((page, i) => {
            // if not mobile view, remove "Profile" from sidebar
            const conditionalRender = (type !== 'mobile' && page.name !== 'Profile') || type === 'mobile';
            if (!conditionalRender) return null;
            return listItemLink({
              link: page.link,
              key: page.key,
              className: classes.link,
              className2: null,
              icon: icons[i],
              text: page.name,
              allowedFor: page.allowedPermissions,
              root: page.root,
            });
          })}
          <ListItem button onClick={() => window.open('http://store.safehealth.me/', '_blank')}>
            <ListItemIcon>
              <ShoppingCartIcon />
            </ListItemIcon>
            <ListItemText primary="Order Tests" />
          </ListItem>
          {/* {listItemButton( */}
          {/* 'virtual-consult', */}
          {/* <AddToQueueIcon />, */}
          {/* 'Virtual Consult', */}
          {/* openVisualConsult, */}
          {/* '/virtual-consult' */}
          {/* )} */}
          <Collapse in={openVisualConsult} timeout="auto" unmountOnExit className={classes.dropdown}>
            <List component="div">
              {virtualConsultPages(currentTenant).map((page) =>
                listItemLink({
                  link: page.link,
                  key: page.key,
                  className: classes.link,
                  className2: classes.nested,
                  root: page.root,
                  text: <Typography variant="body2">{page.name}</Typography>,
                })
              )}
            </List>
          </Collapse>
          {pages2(currentTenant, user).map((page, i) => {
            // if not mobile view, remove "Profile" from sidebar
            const conditionalRender = (type !== 'mobile' && page.name !== 'Profile') || type === 'mobile';
            if (!conditionalRender) return null;
            return listItemLink({
              link: page.link,
              key: page.key,
              className: classes.link,
              className2: null,
              icon: icons[i + 5],
              text: page.name,
              allowedFor: page.allowedPermissions,
              root: page.root,
            });
          })}
          {listItemButton('settings', <SettingsRoundedIcon />, 'Settings', openSettings, '/settings')}
          <Collapse in={openSettings} timeout="auto" unmountOnExit className={classes.dropdown}>
            <List component="div">
              {settingsPages(currentTenant).map((page) =>
                listItemLink({
                  link: page.link,
                  key: page.key,
                  className: classes.link,
                  className2: classes.nested,
                  root: page.root,
                  text: <Typography variant="body2">{page.name}</Typography>,
                })
              )}
            </List>
          </Collapse>
        </AccessControl>
      )}
      {type === 'mobile' && (
        <List component="div">
          {accountAdminAccess && (
            <Link to={`/${currentTenant && currentTenant.id}/account`} className={classes.link}>
              <ListItem button>
                <ListItemIcon>
                  <LockOpenRoundedIcon className={classes.icon} fontSize="small" />
                </ListItemIcon>
                <ListItemText primary={<Typography variant="body2">Account Settings</Typography>} />
              </ListItem>
            </Link>
          )}
          <div className={classes.menuButtons}>
            {/* <ListItem button disableGutters> */}
            {/*  <ListItemText primary={<LanguageMenu mobile />} /> */}
            {/* </ListItem> */}
            <ListItem button onClick={logout} disableGutters style={{ marginBottom: '-20px', zIndex: '999' }}>
              <ListItemText
                primary={
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.logoutBtn}
                    fullWidth
                    disableElevation
                    startIcon={<PowerSettingsNewRoundedIcon />}
                  >
                    Logout
                  </Button>
                }
              />
            </ListItem>
            <ListItem className={classes.mobileVersion} disableGutters style={{ padding: '0' }}>
              <ListItemText
                style={{ color: '#7E7E7E', textAlign: 'center', maxWidth: '225px' }}
                primary={<Typography variant="caption">{utils.appVersion()}</Typography>}
              />
            </ListItem>
          </div>
        </List>
      )}
    </List>
  );
}

const useStyles = makeStyles(SidebarStyles);

export default Sidebar;
